.App {
  text-align: center;
}

body {
  font-family: "Cairo", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.header {
  top: 0;
  position: relative;
  display: flex;
  width: 100%;
  flex-flow: column;
  background-color: white;
  z-index: +2;
}

.header .adminArrow {
  border: solid #8F8F8F;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
  right: 5px;
  position: absolute;
  z-index: +999;
}

.header .adminArrow:hover {
  color: #11ABC5;
  cursor: pointer;
}

.header .topSec {
  display: flex;
  flex-flow: row;
  height: 150px;
  align-items: center;
}

.header .topSec .search {
  left: 0;
  position: absolute;
}

.header .topSec .logoSec {
  right: 0;
  position: absolute;
  display: flex;
  direction: ltr;
}

.header .topSec .logoSec .logo {
  display: flex;
  direction: rtl;
  position: relative;
}

.header .topSec .logoSec .name {
  justify-content: center;
  display: flex;
  align-items: center;
  color: #000080;
}

.header .topSec .logo img {
  width: 120px;
  margin-right: 15px;
}

.header .signAdminDD {
  display: flex;
  flex-flow: column;
  list-style: none;
  border-radius: 20px;
  box-shadow: 0px 2px 4px 1px #0000003d;
  padding: 20px;
  position: absolute;
  background-color: white;
  top: 10px;
  z-index: +999;
}

.header .signAdminDD .click {
  list-style: none;
  transition: 0.1s;
  color: #000080;
  width: max-content;
}

.header .signAdminDD .click:hover {
  cursor: pointer;
  color: #61dafb;
}

.search .searchIqon {
  width: 50px;
}

.search form {
  border: 1px solid #0073CF;
  border-radius: 3px;
  margin-left: 20px;
  display: flex;
  justify-content: end;
  background-color: white;
  direction: ltr;
  align-items: center;
}

.search button {
  background-color: white;
  border: 0;
  height: auto;
}

.search input {
  width: 200px;
  direction: rtl;
  padding: 10px;
  border: 0;
  font-size: 12pt;
  font-family: 'Cairo';
}

.search input[type=text]:focus {
  outline: none;
}

.header .bottomSec {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header .bottomSec .navbar {
  display: flex;
  flex-flow: row;
  direction: rtl;
}

.header .bottomSec .navSec {
  margin: 0 25px;
  margin-bottom: 5px;
  font-size: 14pt;
  font-weight: 600;
  color: #000080;
  transition: 0.1s;
}

.header .bottomSec .navSec:hover {
  cursor: pointer;
  color: #61dafb;
}

.header a:visited {
  color: #000080;
}

.header .toggle {
  display: flex;
  flex-flow: column;
  z-index: +9;
}

.header .dropdown {
  border-radius: 20px;
  box-shadow: 0px 2px 4px 1px #0000003d;
  padding: 20px;
  position: absolute;
  background-color: white;
}

.header .dropdown ul {
  padding: 0;
}

.header .dropdown ul li {
  list-style: none;
  margin: 10px;
  transition: 0.1s;
}

.header .dropdown ul li:hover {
  cursor: pointer;
  color: #61dafb;
}

.header .secDrobdwon {
  top: 90px;
  right: 5px;
  position: absolute;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.arrow {
  border: solid #000080;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  margin-right: 10px;
}

/**** footer ***/
.footer {
  width: 100%;
  bottom: 0;
  position: relative;
  background-color: #00008069;
}

.footer .topSec {
  height: auto;
  display: flex;
  direction: rtl;
  float: inline-end;
  width: 100%;
}

.footer .logoSec {
  display: flex;
  flex-flow: row;
  direction: rtl;
  align-items: center;
  color: #000080;
  width: 60%;
}

.footer .logoSec .logo img {
  width: 200px;
}

.footer .topSec .socialmediaSec {
  width: 40%;
  align-items: center;
  display: flex;
  position: relative;
  direction: ltr;
  margin-left: 100px;
}

.footer .topSec .socialmediaSec .i,
.footer .topSec .socialmediaSec .f,
.footer .topSec .socialmediaSec .y {
  width: 40px;
  margin: 10px;
}

.footer .topSec .socialmediaSec .x {
  width: 40px;
  margin: 10px 5px;
}

.footer .bottomSec {
  color: white;
}

/*** landingPage */

.landingPage {
  display: flex;
  flex-flow: column;
}

.landingPage .ghascImg .Title {
  position: relative;
  right: 0;
  direction: rtl;
  float: right;
  font-size: 25pt;
  color: #ffffff;
  font-weight: 500;
  z-index: +2;
  height: 100%;
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.landingPage .ghascImg .Title .en {
  font-size: 15px;
}

.landingPage .ghascImg .Title .bottom {
  font-size: 15px;
  font-weight: 300;
  margin-top: 10%;
  margin-bottom: 30px;
  bottom: 0;
  position: absolute;
  width: 100%;
}

.landingPage .ghascImg {
  position: relative;
  height: fit-content;
  padding: 0;
  margin-bottom: 50px;
}

.landingPage .ghascImg img {
  top: 0;
  position: static;
  left: 0;
  width: 100%;
  margin: 0;
  height: 100%;
}

.landingPage .ghascImg .imgShade {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: +1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landingPage .articleSec {
  display: flex;
  flex-flow: column;
  width: 100%;
  direction: rtl;
}

.landingPage .articleSec .artTit {
  display: flex;
  float: right;
  direction: rtl;
  width: 300px;
  border-bottom: 1px solid #949494;
  margin-bottom: 50px;
}

.landingPage .articleSec .artTit .Tit {
  color: #000080;
  direction: rtl;
  float: right;
  font-size: 20pt;
  margin-right: 20px;
  font-weight: 700;
}

.landingPage .articleSec .artTit .allArt {
  color: #0073CF;
  float: right;
  font-size: 10pt;
  margin-right: 20px;
  display: flex;
  align-items: end;
}

.landingPage .articleSec .articles {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: baseline;
}

.landingPage .articleSec .articles .art {
  min-width: 20%;
  min-height: 200px;
  padding: 20px;
  margin: 0 2%;
  background-size: cover;
  background-image: url("../public/imgs/art-background.png");
}

.landingPage .articleSec .articles .art .date {
  color: white;
  font-size: 8pt;
  margin: 5px;
}

.landingPage .articleSec .articles .art .title {
  color: white;
  font-size: 14pt !important;
  margin: 5px;
}

.landingPage .articleSec .articles .art .brief {
  color: white;
  font-size: 10pt;
  margin: 5px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-lines: 3;
  /* عرض 3 أسطر */
}

.landingPage .articleSec .articles .art .button {
  background-color: #87CEEB;
  border-radius: 5px;
  padding: 10px;
  border: 0;
  font-family: 'Cairo';
  margin: 20px;
  color: white;
  transition: 0.2s;
}

.landingPage .articleSec .articles .art .button:hover {
  background-color: #0073CF;
}

.landingPage .pubSec {
  margin: 80px 0;
}

.landingPage .pubSec .pubTit {
  height: 100px;
  background-color: #0073CF;
  padding: 30px;
  color: white;
  font-size: 20pt;
}

.landingPage .pubSec .sections {
  display: flex;
  flex-flow: row;
  justify-content: center;
}

.landingPage .pubSec .sections .sec {
  background-color: #87CEEB;
  min-height: 120px;
  top: -50px;
  position: relative;
  margin: 0 50px;
  width: 30%;
  padding: 10px;
  color: #0073CF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15pt;
  font-weight: 600;
  transition: 0.3s;
}

.landingPage .pubSec .sections .sec:hover {
  width: 20%;
  cursor: pointer;
}

.landingPage .pubSec .sections a {
  width: 50%;
  justify-content: center;
  display: contents;
}

.articles {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.articles .content {
  box-shadow: -1px 1px 8px 1px #4f4f4f;
  border-radius: 20px;
  margin: 50px;
  padding: 20px;
  width: 60%;
  display: flex;
  flex-flow: column;
}

.articles .content .for {
  color: #4f4f4f;
  direction: rtl;
  float: right;
  display: flex;
  right: 0;
  position: relative;
  font-size: 13pt;
}

.articles .content .title {
  border-bottom: 1px solid #949494;
  direction: rtl;
  display: flex;
  padding: 0 10px;
  font-size: 20pt;
}

.articles .content .arts {
  display: flex;
  align-items: end;
  flex-flow: column-reverse;
}

.articles .content .art {
  margin: 30px;
  margin-bottom: 50px;
}

.articles .content .arts .art .artTit {
  color: #000080;
  font-weight: 700;
  direction: rtl;
  display: flex;
  margin: 10px;
}

.articles .content .arts .art .artTit a {
  color: #000080;
}

.articles .content .arts .art .artDate {
  color: #949494;
  font-size: 11pt;
  margin: 10px;
  display: flex;
  direction: rtl;
}

.articles .content .arts .art .artBrief {
  text-align: right;
  display: flex;
  direction: rtl;
}

.articles .content .share {
  display: flex;
  direction: rtl;
  align-items: center;
  justify-content: center;
}

.articles .content .share .tit {
  font-weight: 600;
  color: #000080;
}

.articles .content .share .shareSocial button {
  margin: 10px;
}

.articles .content .artTitle {
  font-size: 20pt;
}

.articles .content .artDate {
  color: #949494;
  direction: rtl;
  display: flex;

}

.articles .content .artContent {
  text-align: right;
  display: flex;
  direction: rtl;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.articles .content .artContent .pdfSec {
  display: flex;
  flex-flow: column;
  width: 100%;
}

.articles .content .artContent .pdfSec object {
  width: 100%;
  height: 400px;
}

.articles .content .artContent .cont {
  font-size: 15pt;
  display: flex;
  width: 100%;
}

.articles .content .artContent .source {
  margin: 20px;
}

.articles .content .artContent .subImg {
  width: 80%;
}

.articles .content .artContent .topic {
  font-size: 18pt;
  margin: 30px 0;
  text-align: right;
  width: 100%;
}

.SearchResults .content {
  box-shadow: -1px 1px 8px 1px #4f4f4f;
  border-radius: 20px;
  margin: 50px;
  padding: 20px;
}

.SearchResults .searchSection {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  direction: rtl;
  background-color: #87CEEB;
}

.SearchResults .searchSection .loading {
  right: 0;
  margin-right: 30px;
  position: absolute;
}

.SearchResults .searchSection .searchWord {
  display: flex;
  margin: 10px;
  color: #000080;
  right: 10%;
  position: absolute;
}

.SearchResults .searchSection .searchWord .word {
  margin-right: 1px;
}

.SearchResults .searchSection .searchInput {
  display: flex;
  margin: 10px;
  left: 10%;
  position: absolute;
}

.SearchResults .searchSection .searchInput form {
  height: 60px;
}

.SearchResults .content .searchRes .result {
  display: flex;
  flex-flow: column;
  text-align: justify;

  direction: rtl;
}

.SearchResults .content .searchRes .result .tit {
  color: #000080;
  direction: rtl;
  float: right;
  font-weight: 600;
}

.SearchResults .content .searchRes .result .sec,
.SearchResults .content .searchRes .result .date {
  display: flex;
  font-size: 10pt;
}

.SearchResults .content .searchRes .result .gray {
  color: #949494;
}

.SearchResults .content .searchTitle {
  border-bottom: 1px solid #949494;
  direction: rtl;
  display: flex;
  padding: 0 10px;
  font-size: 16pt;
  margin-bottom: 20px;
}

.SearchResults .content .searchTitle .count {
  color: #25D366;
  margin-left: 5px;
}





/*//////////login*/

.login {
  background-color: #ffffff;
  justify-content: center;
  width: 100%;
  display: flex;
  flex-flow: column;
}

.login .div-2 {
  display: flex;
  background-color: #ffffff;
  min-height: 500px;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-flow: column;
}

.login .header-instance {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}

.login .footer-instance {
  left: 0 !important;
  position: absolute !important;
  top: 674px !important;
}

.login .text-wrapper-2 {
  color: #0073CF;
  direction: rtl;
  font-size: 24px;
  font-weight: 600;
  height: 60px;
  letter-spacing: 0;
  line-height: normal;
  position: static;
  text-align: center;
  top: 144px;
  width: 325px;
  margin-top: 120px;
}

.login .overlap-2 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #0073CF;
  border-radius: 20px;
  min-height: 382px;
  position: static;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 50%;
  margin-bottom: 50px;
  flex-flow: column;
}

.login .overlap-2 .cont {
  display: flex;
  flex-flow: row;
  direction: rtl;
  width: 100%;
  justify-content: space-evenly;
  margin: 10px;
}


.login .text-wrapper-3 {
  color: #000080;
  direction: rtl;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: static;
  margin: 10px;
}

.login .text-wrapper-4 {
  color: #000080;
  direction: rtl;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: static;
  margin: 10px;
}

.login .text-wrapper55 {
  color: #000080;
  direction: rtl;
  font-size: 16px;
  font-weight: 600;
  height: 43px;
  left: 434px;
  letter-spacing: 0;
  line-height: normal;
  position: static;
  text-align: center;
  top: 80px;
}

.login .rectangle-2 {
  border: 0;
  background-color: #f2f2f2;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #0073CF;
  min-height: 38px;
  direction: rtl;
  position: static;
  top: 80px;
  width: 60%;
  margin-bottom: 20px;
}

.login .rectangle {
  border: 0;
  background-color: #f2f2f2;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #0073CF;
  min-height: 38px;
  direction: rtl;
  position: static;
  top: 160px;
  width: 60%;
}

.login .rectangle-2:focus {
  outline: none;
  background-color: #e2f2f5;
  border: 0;
  border-bottom: 4px solid #0073CF;
}

.login .rectangle:focus {
  outline: none;
  background-color: #e2f2f5;
  border: 0;
  border-bottom: 4px solid #0073CF;
}


.login .div-wrapper {
  background-color: #0073CF;
  border-radius: 20px;
  box-shadow: 0px 4px 4px #00000040;
  height: 77px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 278px;
  width: 258px;
}

.login .text-wrapper-5 {
  color: #ffffff;
  border: 0;
  direction: rtl;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: static;
  text-align: center;
  width: 154px;
  margin-top: 30px;
  transition: 0.1s;
  font-family: 'CAIRO';
}

.login .text-wrapper-5:hover {
  color: #0073CF;
}

.login .div-wrapper:hover {
  background-color: #ffffff;
  border: 1px solid #0073CF;
  border-radius: 20px;
  box-shadow: 0 0;
  cursor: pointer;
}




/*////////// dashboard*/
.dashboard {
  background-color: #ffffff;
  justify-content: center;
  width: 100%;
  display: flex;
  direction: rtl;
}

.dashboard .remain {
  width: 80%;
}

.dashboard .navAdmin {
  width: 100%;
  text-decoration: none;
}


.dashboard .mainCont {
  height: max-content;
  display: flex;
  justify-content: center;
  font-family: 'CAIRO';
  left: 0;
  position: relative;
  min-height: 1000px;
}

.dashboard .mainCont .cont {
  width: 80%;
}

.dashboard .div-2 {
  background-color: #ffffff;
  position: relative;
  width: 100%;
  display: flex;
}

.dashboard .design-component-instance-node-2 {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}

.dashboard .footer-instance {
  left: 0 !important;
  position: absolute !important;
  top: 1357px !important;
}

.dashboard .sidebar {
  background-color: #87CEEB;
  right: 0;
  position: sticky;
  min-width: 20%;
  z-index: +99;
  box-shadow: -1px 2px 11px 0px hsla(0, 0%, 41%, .75);
}

.dashboard .sidebar .dropdown {
  direction: ltr;
}


.dashboard .sidebar .dropdown div {
  direction: rtl;
  text-align: right;
  list-style: none;
  font-size: 11pt;
  padding: 5px;
  color: #0073CF;
}

.dashboard .sidebar .dropdown div:hover {
  background-color: #0073CF;
  color: #87CEEB !important;
}

.dashboard .fixed {
  position: fixed;
  width: 20%;
  justify-content: end;
  display: flex;
  flex-flow: column;
  height: 600px;
}

.dashboard .sidebar .DBTitle {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 30px;
  top: 0;
  position: absolute;
  margin-bottom: 50px;
}

.dashboard .text-wrapper-3 {
  color: #ffffff;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 20px;
  font-weight: 600;
  left: 40px;
  letter-spacing: 0;
  line-height: normal;
  position: static;
  text-align: center;
  top: 34px;
  width: 136px;
}

.dashboard .div-wrapper {
  color: #0073CF;
  font-size: 14pt;
  min-height: 51px;
  list-style: none;
  left: 0;
  position: static;
  margin: 0;
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 2px;
  width: 100%;
}

.dashboard .div-wrapper:hover {
  background-color: #0073CF;
  color: #87CEEB;
  cursor: pointer;
}

.dashboard .dashboardList {
  display: flex;
  position: absolute;
  flex-wrap: wrap;
  top: 100px;
  padding: 0;
  width: 100%;
}

.dashboard .text-wrapper-4 {
  color: #ffffff;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 20px;
  font-weight: 600;
  height: 31px;
  left: 99px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 7px;
  width: 67px;
}

.dashboard .overlap-5 {
  background-color: #a4dce5;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-color: #11abc5;
  height: 51px;
  left: 0;
  position: absolute;
  top: 269px;
  width: 270px;
}

.dashboard .text-wrapper-5 {
  color: #006171;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 20px;
  font-weight: 600;
  height: 31px;
  left: 99px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 67px;
}

.dashboard .overlap-6 {
  background-color: #a4dce5;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-color: #11abc5;
  height: 51px;
  left: 0;
  position: absolute;
  top: 218px;
  width: 270px;
}

.dashboard .text-wrapper-6 {
  color: #006171;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 20px;
  font-weight: 600;
  height: 31px;
  left: 83px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 7px;
  width: 100px;
}

.dashboard .overlap-7 {
  background-color: #a4dce5;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-color: #11abc5;
  height: 51px;
  left: 0;
  position: absolute;
  top: 167px;
  width: 270px;
}

.dashboard .text-wrapper-7 {
  color: #006171;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 20px;
  font-weight: 600;
  height: 31px;
  left: 80px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 7px;
  width: 106px;
}

.dashboard .dashboard-customize {
  height: 48px;
  left: 176px;
  position: static;
  top: 34px;
  width: 49px;
}

.dashboard .overlap-8 {
  background-color: #a4dce5;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-color: #11abc5;
  height: 51px;
  left: 0;
  position: absolute;
  top: 320px;
  width: 270px;
}

.dashboard .text-wrapper-8 {
  color: #006171;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 20px;
  font-weight: 600;
  height: 31px;
  left: 41px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 7px;
  width: 184px;
}

.dashboard .edit {
  height: 96px;
  left: 249px;
  position: absolute;
  top: 540px;
  width: 21px;
}

.dashboard .overlap-9 {
  background-color: #87CEEB;
  border-radius: 10px;
  color: #0073CF;
  font-size: 15pt;
  box-shadow: 0px 4px 4px #00000040;
  height: 86px;
  right: 50px;
  position: absolute;
  top: 136px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 242px;
}

.dashboard .overlap-9:hover {
  background-color: #0073CF;
  color: #ffffff;
  cursor: pointer;
}

.dashboard .text-wrapper-9 {
  color: #006171;
  direction: rtl;
  font-family: 'Baloo Bhaijaan 2';
  font-size: 20px;
  font-weight: 600;
  height: 36px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  width: 92px;
}

.dashboard .create {
  position: absolute;
  display: block;
  right: 20px;
  width: 30px;
}

.dashboard .overlap-10 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #0073CF;
  border-radius: 20px;
  position: relative;
  flex-flow: column;
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
  display: flex;
  font-family: 'CAIRO';
  width: 90%;
  padding-bottom: 70px;
}

.dashboard .overlap-10 img {
  width: 70%;
}

.dashboard .overlap-11 {
  height: 64px;
  /* left: 182px; */
  position: absolute;
  /* top: 246px; */
  justify-content: center;
  display: flex;
  width: 100%;
  bottom: 0;
}

.dashboard .delete {
  height: 25px;
  left: 362px;
  position: absolute;
  top: 20px;
  width: 25px;
}

.dashboard .overlap-12 {
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d9d9d9;
  min-height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* left: 85px; */
  position: static;
  top: 16px;
  width: 70%;
}

.dashboard .text-wrapper-10 {
  color: #000000;
  direction: rtl;
  font-size: 20px;
  font-weight: 100;
  min-height: 35px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  position: static;
  top: 14px;
  width: auto;
}

.dashboard .p {
  color: #adadad;
  direction: rtl;
  font-size: 20px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: normal;
  position: static;
  text-align: center;
  top: 94px;
  width: 80%;
  position: static;
}

.dashboard .text-wrapper-11 {
  color: #0073CF;
  direction: rtl;
  font-size: 20px;
  font-weight: 600;
  height: 36px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 278px;
  right: 0;
  margin-right: 40px;
}

.dashboard .overlap-13 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #11abc5;
  border-radius: 20px;
  height: 311px;
  left: 38px;
  position: absolute;
  top: 668px;
  width: 1082px;
}

.dashboard .component-1 {
  left: 181px !important;
  position: absolute !important;
  top: 245px !important;
}

.dashboard .overlap-14 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #11abc5;
  border-radius: 20px;
  height: 311px;
  left: 38px;
  position: absolute;
  top: 1007px;
  width: 1082px;
}

.dashboard .component {
  background-color: #87CEEB;
  height: 64px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 70%;
  bottom: 0;
  border-radius: 20px 20px 0 0;
}

.dashboard .component .line {
  height: 34px;
  left: 201px;
  object-fit: cover;
  position: absolute;
  top: 15px;
  width: 1px;
}

.dashboard .component .img {
  height: 34px;
  left: 479px;
  object-fit: cover;
  position: absolute;
  top: 15px;
  width: 1px;
}

.dashboard .component .overlap {
  height: 100%;
  right: 5px;
  position: absolute;
  /* top: 14px; */
  width: 30%;
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
}

.dashboard .component .overlaphh1 {
  height: 100%;
  right: 5px;
  position: absolute;
  /* top: 14px; */
  width: 50%;
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
}

.dashboard .component .overlap1 {
  height: 100%;
  right: 30%;
  position: absolute;
  /* top: 14px; */
  width: 40%;
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
}

.dashboard .component .overlap2 {
  height: 100%;
  left: 0;
  position: absolute;
  /* top: 14px; */
  width: 30%;
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.dashboard .component .overlaphh {
  height: 100%;
  left: 0;
  position: absolute;
  width: 50%;
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.dashboard .component .text-wrapper {
  color: #0073CF;
  direction: rtl;
  font-size: 20px;
  font-weight: bolder;
  height: 34px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  top: 0;
  width: 100%;
  border-left: 1px solid gray;
}

.dashboard .component .text-wrapper1 {
  color: #0073CF;
  direction: rtl;
  font-size: 20px;
  font-weight: bolder;
  height: 34px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  top: 0;
  width: 100%;
}

.dashboard .component .vector {
  right: 20%;
  position: absolute;
  width: 25px;
  top: 20px;
}

.dashboard .component .vector-2 {
  right: 25%;
  position: absolute;
  width: 20px;
  top: 20px;
}

.dashboard .component .div {
  height: 35px;
  left: 61px;
  position: absolute;
  top: 14px;
  width: 89px;
}

.dashboard .component .eye {
  right: 25%;
  position: absolute;
  width: 35px;
}

.dashboard .cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  top: 20%;
  position: static;
  margin-top: 350px;
  align-items: center;
  flex-direction: column-reverse;
}


.dashboard .bottomPart .settings {
  color: black;
}

.dashboard .bottomPart .signout {
  color: black;
  cursor: pointer;
}

.updateScreen {
  position: relative;
  width: 100%;
  height: 100%;
  flex-flow: column;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.updateScreen .formTit {
  font-size: 20pt;
  margin: 50px;
  color: #000080;
  text-decoration: overline;
  font-weight: 800;
}

.updateScreen .updateForm {
  width: 60%;
  height: auto;
  direction: rtl;
  display: flex;
  flex-direction: column;
  position: static;
  border-radius: 10px;
  background-color: #00008033;
  padding: 3rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.updateScreen .updateForm label {
  width: 100%;
  display: flex;
  font-weight: 600;
  color: #000080;
}

.updateScreen .updateForm img {
  width: 70%;
}

.updateScreen .updateForm input {
  border-radius: 0.6rem;
  width: 70%;
  padding: 10px;
}

.updateScreen .updateForm .sbmtBtn {
  background-color: #0073CF;
  border-radius: 5px;
  padding: 10px;
  border: 0;
  font-family: 'Cairo';
  margin: 20px;
  color: white;
  transition: 0.2s;
  width: 20%;
}

.updateScreen .updateForm .sbmtBtn:hover {
  background-color: #87CEEB;
}

.jodit-status-bar-link {
  display: none;
}

@media (max-width:750px) {
  .header .topSec .logoSec .name {
    display: none;
  }

  .search input {
    width: auto;
  }

  .landingPage .articleSec .articles .art .button {
    font-size: 8pt;
    margin: 0;
  }

  .dashboard .dashboard-customize {
    display: none;
  }

  .landingPage .ghascImg .Title {
    font-size: 20pt;
  }

  .landingPage .ghascImg .Title .bottom {
    margin-bottom: 20px;
    font-size: 11px;
  }
}

@media (max-width:600px) {
  .header .bottomSec .navSec {
    margin: 0 10px;
    font-size: 11pt;
  }


  .header .topSec {
    flex-flow: column;
    height: auto;
    margin-bottom: 20px;
  }

  .header .topSec .logoSec {
    position: relative;
  }

  .header .topSec .search {
    left: auto;
    position: relative;
  }

  .search form {
    margin-left: 0;
  }

  .footer .logoSec .name {
    display: none;
  }

  .landingPage .ghascImg .Title {
    font-size: 12pt;
  }

  .landingPage .ghascImg img {
    height: 300px;
    width: auto;
  }
}

@media (max-width:400px) {
  .landingPage .ghascImg .Title {
    font-size: 13pt;
  }

  .articles .content .title {
    font-size: 15pt;
  }

  .footer .topSec .socialmediaSec {
    flex-flow: column;
    margin: 0;
  }

  .dashboard .component .text-wrapper1,
  .dashboard .component .text-wrapper {
    font-size: 13px;
  }

  .landingPage .pubSec .sections .sec {
    margin: 0 10px;
  }
}